/**
 *@file CDSelect.scss
 *@brief Reusable select component style
 *@date Jun, 2024
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */

 @import "./variables.scss";

.kki-select {
    label{
        font-size: $KKI-FS14;
        margin-bottom: 5px;
        &:empty{
            display: none;
          }
    }
    .custom-select {
            color: $KKI-TextColor;
            font-size:$KKI-FS14;
            font-weight:$KKI-Regular;
            @include input-radius();
            background: $KKI-InputBgColorOp;
            border:1px solid $KKI-InputBrdrColor;

            &:hover,
            &:focus,
            &:active {
                border: 1px solid $KKI-InputBrdrFocusColor;
                box-shadow: none !important;
                outline: none;
            }
            &.active {
                border: 1px solid $KKI-ActiveBrdrColor;
              }
            &.error {
                border: 1px solid $KKI-ValidationRedColor !important;
              }
            
            
        &__control {
            color: $KKI-TextColor;
            font-size:$KKI-FS14;
            font-weight:$KKI-Regular;
            border:0px solid $KKI-InputBrdrColorOp;
            @include input-radius();
            padding-left: 12px !important;
            padding-right: 5px !important;
            box-shadow: none !important;
            
            
            
            &:hover,
            &:focus,
            &:active {
                background: transparent;
                border:0;
                box-shadow: none !important;
                outline: none;

               /* border: 1px solid $KKI-InputBrdrFocusColor !important;
                box-shadow: none !important;
                outline: none;*/
            }

            
        }

        &__control--is-disabled {
            background: $KKI-DisabledInput !important;;
        }
        /*&__input{
            color: transparent!important;
        }*/
        &__value-container {
            padding: 0;
        }

        &__menu {
            padding: 10px 0;
            margin-top: 2px;
            @include input-radius();
        }

        &__option {
            background-color: none !important;

            &:hover,
            &:focus,
            &:active {
                cursor: pointer;
            }
        }

        &__option--is-focused {
            background: #F3F3F3;

            &:active,
            &:focus,
            &:hover {
                background: #F3F3F3;
            }
        }

        &__option--is-selected {
            color: $KKI-TextColor;
            background: #D8CBFF;

            &:active,
            &:focus,
            &:hover {
                color: $KKI-TextColor;
                background: #D8CBFF;
            }
        }

        &__indicator-separator {
            display: none !important;
        }
        &__indicator{
            padding-right: 0;
        }
        &__dropdown-indicator{
            svg{
                fill: $KKI-TextColor;
            }
        }
        >div{
            border: 0;
        }
    }
    

}
span.error {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyIDIyQzYuNDc3IDIyIDIgMTcuNTIzIDIgMTJTNi40NzcgMiAxMiAyczEwIDQuNDc3IDEwIDEwLTQuNDc3IDEwLTEwIDEwWm0wLTJhOCA4IDAgMSAwIDAtMTYuMDAxQTggOCAwIDAgMCAxMiAyMFpNMTEgN2gydjJoLTJWN1ptMCA0aDJ2NmgtMnYtNloiIGZpbGw9IiNENTAwMDAiLz48L3N2Zz4=) 0 0 no-repeat;
    color: $KKI-ValidationRedColor;
    font-size: $KKI-FS14;
    font-weight:$KKI-Regular;
    margin-top: 5px;
    padding-left: 32px;
    display: block;
    min-height: 24px;
    padding-top:3px;
}
.fw-normal{
    label{
        font-weight: normal !important;
    }
}
.drop-265{
    z-index: 11;
    .custom-select__menu{
        width: 265px;
        right: 0;
        z-index: 111;
    }
}