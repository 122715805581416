@import './variables.scss';

.roster-tab-main {
    .roster-details-main {
        border-top: 1px solid $KKI-InputBrdrColor;
        min-height: 95px;
        .roster-date {
            max-width: 75px;
            border-right: $KKI-InputBrdrColor solid 1px;
            font-weight: $KKI-Medium;

            @media (max-width:490px) {
                max-width: 65px;
            }
        }

        .roster-shift-details-main {
            padding: 10px;

            @media (max-width:490px) {
                padding: 5px 10px;
            }


        }

        &:last-child {
            border-bottom: 1px solid $KKI-InputBrdrColor;
        }
    }
}

.roster-shift {
    background-color: $KKI-InputBgColor;
    @include btn-radius;
    padding: 8px 50px 8px 10px;
    min-height: 75px;
    position: relative;
    margin-top: 5px !important;

    &:first-child {
        margin-top: 0 !important;
    }

    @media (max-width:490px) {
        min-height: 65px;
        padding-right: 35px;
    }

    .shift-name {
        font-weight: $KKI-Medium;

        @media (max-width:490px) {
            font-size: 11px;
        }
    }

    .ca-count {
        position: relative;
        margin: 5px 0;

        @media (max-width:490px) {
            font-size: 10px;
            margin: 3px 0;
        }

        .colorgroup {
            padding-left: 10px;
        }
        .lateduty{
            color: #FF00E5;
            font-weight: $KKI-Bold;
        }
    }

    .shift-time {
        @media (max-width:490px) {
            font-size: 10px;
        }
    }

    &.draftshift {
        background-color: $KKI-DraftColor;
    }

    &.publishedshift {
        background-color: $KKI-PublishedColor;
    }
    &.oncallshift {
        background-color: $KKI-OnCallColor;
    }
    &.noshow{
        border: #6D4424 solid 1px;
    }

    .dropdown {
        position: absolute;
        right: 8px;
        top: 8px;

        .dropdown-toggle {
            background-color: $KKI-WhiteColor;
            width: 35px;
            height: 35px;
            padding: 0 10px;
            line-height: 15px;
            border-radius: 3px;

            @media (max-width:490px) {
                width: 20px;
                height: 20px;
                padding: 0 4px;
            }

            svg {
                width: 100%;

                path {
                    fill: $KKI-PrimaryColor
                }
            }

            &::after {
                display: none;
            }

            &:focus {
                border-color: $KKI-WhiteColor;
            }
        }

        .dropdown-menu {
            font-size: $KKI-FS14;
            min-width: 120px;
            border: 0;
            -webkit-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.20);
            -moz-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.20);
            box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.20);

            @media (max-width:490px) {
                font-size: $KKI-FS13;
            }
        }
    }

    &.width-shift {
        width: 50%;
        max-width: 600px;

        @media (max-width:490px) {
            width: 78%;
        }
    }

    &.mid-shift {
        margin: 0 auto;
    }

    &.night-shift {
        margin: 0 0 0 auto;
    }

    &.leave {
        width: 100%;
    }

    &.holiday {
        background: #EFF5F5 !important;
        width: 100%;
    }

    .shift-arrow {
        width: 11px;
        position: absolute;
        bottom: 12px;
        right: 12px;
        line-height: 0;

        &.next-day {
            rotate: 180deg;
        }
    }
}
.colorcode {
    width: 12px;
    height: 12px;
    border-radius: 15px;
    display: inline-block;
    left: 0;
    top: 4px;
    background: $KKI-DisabledInput;
    margin-right: 3px;

    @media (max-width:490px) {
        width: 8px;
        height: 8px;
        top: 2.5px;
    }


    &.yellow {
        background-color: $KKI-YellowColor;
        border: $KKI-YellowBrdrColor solid 1px;
    }

    &.blue {
        background-color: $KKI-BlueColor;
        border: $KKI-BlueBrdrColor solid 1px;
    }

    &.red {
        background-color: $KKI-RedColor;
        border: $KKI-RedBrdrColor solid 1px;
    }
    &.white {
        background-color: $KKI-WhiteColor;
        border: $KKI-PlaceHolderColor solid 1px;
    }
}
.table{
    &.schedule-table{
        min-width: 1214px;
        margin-bottom: 40px;
        th{
            width: 12.5%;
            font-size: 14px;
            font-weight: $KKI-Medium;
            text-align: center;
            padding: 16px 10px;
            span{
                display: block;
            }   
        }
        tr{
            td{
                font-size: 13px;
                padding: 4px;
                .shift-name, .employee-id{
                    font-size: 11px;
                    font-weight: $KKI-Medium;
                    padding-right: 20px;
                }
                .ca-count, .shift-time, .sch-key-indicators, .oncall-label{
                    font-size: 10px;
                }
                
                .roster-shift{
                    padding: 8px;
                    min-height: 90px;
                    .shift-arrow{
                        rotate: -90deg;
                        &.next-day {
                            rotate: 90deg;
                        }
                    }
                }
                .colorgroup{
                    padding-left: 8px;
                }
                .colorcode{
                    width: 8px;
                    height: 8px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
                &:first-child{
                    vertical-align: middle;
                    position: sticky;
                    left: 0;
                    z-index: 0;
                    background-color: #fff;
                    box-shadow: -1px 0px 2px -1px rgba(0,0,0,0.1) inset;
-webkit-box-shadow: -1px 0px 2px -1px rgba(0,0,0,0.1) inset;
-moz-box-shadow: -1px 0px 2px -1px rgba(0,0,0,0.1) inset;
                }
                .holiday{
                    background: #EFF5F5;
                    // position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    left: 0;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 13px;
                    font-weight: $KKI-Medium;
                }

            }
        }
        .dropdown-toggle{
            width: 20px;
            height: 20px;
            padding: 0 4px;
        }
    }
}
.oncall-label{
    margin-top: 2px;
    svg{
        margin-top: -2px;
        margin-left: 4px;
    }
}